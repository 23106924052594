const ComisiiDeSpecialitate = () => {
	return (
		<div className="content_inner">
		<h1>Comisii de specialitate</h1>

		{ /*<ul>
			<li>Comisia de jurisdicție profesională: dr. Szakács Oszkár Balázs, dr. László Zsuzsanna, dr. Tubák Nimród, dr. Biró László, dr. Györfi Sándor.</li>

			<li>Comisia de avizări: dr. Bartalis Zsolt, dr. Solti Norbert, dr. Könczei Csaba. Membru supleant: dr. Biró László.</li>
			<li>Comisia de etică și deontologie profesională: dr. Biró László, dr. Solti Norbert, dr. Lőkös Barna Csaba</li>
			<li>Comisia reunită pentru studii și strategii de dezvoltare și profesional științifică și de învățământ: dr. Tubák Nimród, dr. Szöllösi Attila, dr. Szakács Oszkár Balázs</li>
			<li>Comisia economico-socială și de asigurări de sănătate: Dr. Biró László, Dr. Solti Norbert, Dr. Bartalis Zsolt, Dr. Györfi Sándor, Dr. Lakatos Miklós Zsolt, Dr. Lőkös Barna Csaba, Dr. Sinkó János, Dr. Kacsó István Lajos.</li>
			<li>Comisia administrativă, financiar-contabilă: Dr. Biró László, Dr. Lakatos Csaba, Dr. Lőkös Barna Csaba.</li>
			<li>Comisia de imagine, relații interne și externe: Dr. Györfi Sándor, Dr. Solti Norbert, Dr. László Zsuzsanna</li>
			<li>Reprezentanți ai Colegiului Teritorial Harghita în Comisia de malpraxis care funcționează la nivelul DSP Harghita: Dr. Kacsó István Lajos, Dr. Lőkös Barna Csaba.</li>

		</ul> */}
		</div>
	)
}

export default ComisiiDeSpecialitate;