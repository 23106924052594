import { Link } from 'react-router-dom';

import { HomeDiv} from "./home.styles";
{/*import Alegeri2024 from '../alegeri2024/alegeri2024.component';*/}

const Home = () =>{
	return (
		<div>
			<HomeDiv className="content_inner">
		{/* <Alegeri2024/> */}	 
		<img src="/img/Orvosbal-2025.webp" alt="Orvosibal" />
		<img src="/img/Balul-Medicilor-Harghita.webp" alt="Orvosibal" />
			</HomeDiv>
			
		</div>
	
	);
}

export default Home;