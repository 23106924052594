import styled from "styled-components";

export const HomeDiv = styled.div`
img{
	max-width: 100%;
	padding:5px;
	margin: auto;
	display: block;
}
`;

export const Author = styled.div`
	display: block;
	clear: both;
	margin-top: 100px;
	overflow: hidden;
	@media (max-width: 1100px) {
		margin-top: 50px;
	}
`;

export const AuthorPortre = styled.div`
	width: 50%;
	text-align: right;
	padding-right: 10%;
	float: left;
	img{
		

	}

	@media (max-width: 1100px) {
		padding-right: 5%;
	}
	@media (max-width: 500px) {
		width: 100%;
	}
`;


export const AuthorText = styled.div`
	color : #2D2D2D;
	 width: 100%;
	 line-height: 33px;
	 h2{
		font-family: 'Playfair Display SC';
		font-style: normal;
		font-weight: 700;
		font-size: 56px;
		line-height: 75px;
		margin: 1em 0;
	 }
	 h3{
		font-family: 'Playfair Display SC';
		font-style: italic;
		font-weight: 400;
		font-size: 28px;
		line-height: 37px;
	 }

	 	 button{
		img{
			margin-top: 16px;
			margin-left: 5px;
			width: 29px;
			height: 16px;
		}
	 }

	@media (max-width: 1100px) {
		margin-top: 50px;
		h2{
			font-size: 40px;
			line-height: 50px;
		}
		h3{
			font-size: 20px;
			line-height: 28px;
		}
	}
`;

